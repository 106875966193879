<template>
  <div v-if="readonly">
    <slot name="value" v-bind="{ currentLabel }"><label v-if="!hideLabel" class="col-form-label">{{localizedLabel}}</label><p class="font-bold">{{currentLabel}}</p></slot>
  </div>
  <div v-else>
    <ValidationProvider ref="validator" :name="name" :rules="rulesToUse" >
      <b-form-group slot-scope="{ valid, errors, validated }" :label="localizedLabel" :label-sr-only="hideLabel">
        <multiselect v-model="selection" @input="onInput" v-bind="multiselectOptions" :class="cssClass"></multiselect>
        <b-form-invalid-feedback id="inputLiveFeedback" :state="errors[0] ? false : (valid ? true : null)">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>
<script>
import BaseSelect from './base_select.vue'

import { getObjByValue } from '@/utils.js'

export default {
  extends: BaseSelect,
  props: {
    rules: {
      type: Object|String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rulesToUse: this.rules,
    }
  }
}
</script>
